.account-page
  .main-section
    .username
      display: flex
      justify-content: flex-start
      align-items: center
      margin-bottom: 24px
      .avatar
        width: 60px
        height: 60px
        border: 1px solid var(--highlightFontColor)
        border-radius: 50%
        display: flex
        justify-content: center
        align-items: center
        margin-right: 12px
        font-size: 24px
        color: var(--highlightFontColor)
        p
          text-shadow: 0 0 5px var(--highlightFontColor)
      .info p
        font-size: 28px
        color: var(--whiteColor)
        font-weight: bold
    .get-started
      margin-bottom: 24px
      .sub-heading
        font-weight: bold
        color: var(--whiteColor)
        margin-bottom: 12px
      .started-row
        display: flex
        justify-content: space-between
        align-items: center
        border-radius: 8px
        cursor: pointer
        margin-bottom: 8px
        .pending-status
          display: flex
          justify-content: flex-end
          align-items: center
          p
            margin-left: 12px
    .link-account
      margin-bottom: 24px
      .sub-heading
        font-weight: bold
        color: var(--whiteColor)
        margin-bottom: 12px
      .link-row
        display: flex
        justify-content: space-between
        align-items: center
        border-radius: 8px
        cursor: pointer
        //margin-bottom: 8px
        .pending-status
          display: flex
          justify-content: flex-end
          align-items: center
          p
            margin-left: 12px


