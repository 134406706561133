#chat
  width: 340px
  overflow-y: scroll
  display: flex
  flex-direction: column

  &.fullscreen
    max-width: none
    flex: 1
    width: auto

    .chat-window, .chat-input
      padding-left: 0
      padding-right: 0
      padding-bottom: 0

  &.sidebar
    background-color: rgba(0, 0, 0, 0.1)
    border-left: rgba(255, 255, 255, 0.1) 1px solid

  //   max-width: 310px
  //   position: absolute
  //   z-index: 100
  //   top: 100px
  //   right: 5px
  //   width: 100%
  //   border-radius: 16px
  //   border: 1px solid var(--borderColor)
  //   height: 80vh
  //   margin-top:26px

  .chat-window
    display: flex
    flex: 1
    flex-direction: column
    padding: 16px
    background-color: rgba(0, 0, 0, 0.1)
    // height: 72vh
    overflow: scroll


  .chat-input
    display: flex
    padding: 16px
    align-items: center
    justify-content: space-between

  .chat-input-field
    flex: 1
    outline: none
    background: rgba(#000, 0.3)
    border-radius: 3px
    padding: 11px 15px
    margin-right: 8px
    color: #fff
    border: 1px solid transparent

    &:focus
      border: 1px solid var(--highlightFontColor)

  .cursor
    opacity: 0.4
    color: #fff
    margin: 0 15px

    &:hover, &.active
      opacity: 1
