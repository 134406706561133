.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

/* ==== App header ====== */
#app-header {
  background: rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: space-between;
  padding: 16px 32px;
  align-items: center;
}

#app-header .branding {
  flex: 1;
}

#app-header .connect-btn {
  padding-right: 16px;
}

#app-header .branding p {
  font-size: 24px;
  text-transform: uppercase;
  color: var(--highlightFontColor);
  font-weight: bold;
}

.side-menu-item:hover {
  background-color: rgba(0, 0, 0, 0.9);
  /* border-radius: 10px; */
}

@media (max-width: 600px) {
  #app-header {
    padding: 16px 18px;
  }

  #app-header .branding p {
    font-size: 16px;
  }
}

/* =========== Deposit Modal -=========== */
.deposit-modal .deposit-amount-area .label-field {
  display: flex;
  align-items: center;
  font-size: 14px;
  justify-content: space-between;
}

.deposit-modal .label-field p:nth-of-type(2) {
  margin-bottom: 8px;
}

.deposit-modal .input-area {
  display: flex;
  align-items: center;
  background: var(--primaryBgColor);
  width: 100%;
  border-radius: 6px;
  padding-right: 2px;
  margin-bottom: 4px;
}

.deposit-modal .deposit-amount-area .input-field {
  width: 100%;
}

.deposit-modal .token-view {
  font-weight: 600;
  margin-right: 12px;
  color: var(--primaryColor);
}

.deposit-modal .fiat-value {
  margin-bottom: 16px;
}

.deposit-modal .info {
  color: var(--highlightFontColor);
  text-align: center;
  margin-bottom: 4px;
}

.deposit-modal .cta-btn {
  display: flex;
  align-items: center;
}

.deposit-modal .primary-btn {
  font-weight: bold;
  width: 100%;
}

.deposit-modal .secondary-btn {
  font-weight: bold;
  width: 100%;
}

/* ==== Referral Area ====== */

.referral-main {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.referral-header {
  font-size: 32px;
}

.referral-sub-header {
  font-size: 18px;
  text-align: center;
}

.referral-main .referral-header {
  font-weight: 600;
  margin-bottom: 32px;
}

.referral-main .referral-sub-header {
  font-weight: 600;
  margin-bottom: 24px;
}

.referral-copy {
  display: flex;
  margin-bottom: 24px;
  align-items: center;
  width: 100%;
  justify-content: center;
}

.referral-code-box {
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  font-size: 16px;
  margin-left: 10px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 4px;
  width: fit-content;
  padding: 10px 15px;
}

.referral-copy-btn {
  padding-left: 10px;
}

.referral-social {
  display: flex;
  align-items: center;
  width: 20%;
  justify-content: space-between;
}

@media (max-width: 600px) {
  .referral-social {
    width: 60%;
  }
}

/* ==== Leaderboard Area ====== */

.sorting-selected-value {
  background-color: var(--primaryColor);
  color: white;
}

/* ==== Account Header ======= */
.account-header {
  display: flex;
  font-size: 28px;
  align-items: center;
  margin-bottom: 24px;
  cursor: pointer;
  color: var(--highlightFontColor);
}

.account-header .title {
  font-weight: bold;
  /* margin-left: 12px; */
}
