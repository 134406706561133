#livefeed-main
  border-radius: 16px
  border: 1px solid var(--borderColor)
  display: flex
  flex-direction: row
  margin: 0 auto 20px
  max-width: 800px
  overflow: hidden
  padding: 8px
  position: relative

  .live-marker
    display: flex
    justify-content: center
    align-items: center
    position: absolute
    z-index: 1
    background: rgba(0, 0, 0, 0.9)
    border: 1px solid var(--borderColor)
    border-radius: 16px
    min-width: 50px
    padding: 3px


    .live-red-dot
      background: red
      width: 10px
      height: 10px
      border-radius: 16px
      margin-right: 6px


  .livefeed-row
    margin-right: 10px

  .livefeed-card
    position: relative
    height: 100px
    width: 80px
    background-color: #000
    background-position: center
    background-size: contain
    border-radius: 8px

  .livefeed-data
    display: flex
    flex-direction: column
    align-items: center
    font-size: 12px

  b
    color: green
