.comment-main
  // border: 1px solid rgba(255, 255, 255, 0.2)
  // max-width: 90%
  // padding: 8px 0
  border-radius: 8px
  margin-bottom: 10px
  overflow-wrap: anywhere
  background: #FFFFFF10
  width: 100%
  padding: 6px 12px

  .comment-name
    margin-right: 4px
    font-weight: bold
