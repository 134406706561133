#balance-button
  background: var(--secondaryBtnColor)
  color: #fff
  padding: 9px 18px
  border-radius: 8px
  box-shadow: 2px 2px 5px rgba(0,0,0,0.5)
  border-style: none

#sorter-main
  display: flex
  margin-bottom: 30px
  margin-right: 40px
  justify-content: flex-end

  #sorter-values
    border: 1px solid rgba(255, 255, 255, 0.2)
    margin-right: 10px
    padding: 5px 15px
    cursor: pointer
    font-weight: 600



@media (max-width: 600px)
  #sorter-main
    margin-right: 0px
