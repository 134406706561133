/* ==== Prizes & Intro Area ======

#prizes
  max-width: 800px
  margin-left: auto
  margin-right: auto
  margin-bottom: 16px
  color: var(--whiteColor)

#intro, #explain
  max-width: 800px
  margin-left: auto
  margin-right: auto
  margin-bottom: 16px
  color: var(--whiteColor)

  b
    color: var(--highlightFontColor)

#prizes
  b
    color: var(--highlightFontColor)
    text

  #prizes-bg
    background-position: left
    background-size: cover
    background-repeat: no-repeat
    width: 300px
    height: 100px
    @media (max-width: 600px)
      width: 100%
      height: 150px

#intro, #prizes
  a, a:visited, a:focus
    color: var(--highlightFontColor)
    text-decoration: none
    border-bottom: 2px dotted var(--highlightFontColor)

#prizes
  padding: 0
  display: flex
  flex-direction: row
  align-items: center
  overflow: hidden
  @media (max-width: 600px)
    display: block

  img
    max-width: 100px

  p
    padding: 16px

/* ==== Playing Area ======

#playing-area
  max-width: 800px
  width: 100%
  margin-left: auto
  margin-right: auto
  margin-top: 16px
  margin-bottom: 16px

  .connect-btn
    display: flex
    justify-content: center

  .title
    font-size: 16px
    font-weight: 600
    text-align: center
    margin-bottom: 24px

  .input-area
    display: flex
    align-items: center
    background: var(--primaryBgColor)
    width: 100%
    border-radius: 6px
    padding-right: 2px

  .sub-input-area
    display: flex
    align-items: center
    width: 100%

  .mo-button-row
    display: flex

  .input-area
    .input-field
      flex: 1

    .token-view
      font-weight: 600
      margin-right: 12px
      color: var(--primaryColor)

    .token-view-with-dropdown
      display: flex
      align-items: center
      font-weight: 600
      margin-right: 12px
      color: var(--primaryColor)
      cursor: pointer
      span
        margin-right: 4px
      svg
        fill: var(--primaryColor)

  .fiat-value
    font-size: 12px
    margin-left: 12px
    margin-top: 4px

  .secondary-btn
    margin: 0 4px

  .playing-area-row
    display: flex
    align-items: baseline
    margin-bottom: 32px

    .bet-amount-area
      width: 60%
      margin-right: 12px

      .label-field
        display: flex
        align-items: center
        font-size: 14px
        justify-content: space-between

        p:nth-of-type(2)
          margin-bottom: 8px

    .payout-area
      width: 40%
      margin-left: 12px

      .payout-value
        font-weight: bold

  .info-row
    display: flex
    justify-content: space-between
    align-items: center
    margin-top: 24px
    margin-bottom: 32px

    .info-col
      width: 33.33%
      text-align: center
      padding: 0 12px
      border-right: 1px solid rgba(255, 255, 255, 0.2)

      &:nth-of-type(3)
        border: none

      .info-value
        font-size: 18px
        color: var(--whiteColor)
        font-weight: bold

  .info
    color: var(--highlightFontColor)
    text-align: center
    max-width: 50%
    margin: 15px auto

  .primary-btn
    margin-left: auto
    margin-right: auto
    width: 50%
    font-weight: bold

    &.funding-needed
      border: 1px solid var(--highlightFontColor)
      background-color: rgba(0, 0, 0, 0.1)
      color: var(--highlightFontColor)

@media (max-width: 600px)
  #playing-area
    .playing-area-row
      flex-direction: column

      .bet-amount-area
        width: 100%

    .input-area
      flex-direction: column
      padding-bottom: 8px

    .mo-button-row
      display: flex
      width: 100%
      justify-content: space-around

      .secondary-btn
        width: 100%

    .playing-area-row .payout-area
      margin-top: 16px
      width: 100%
      margin-left: 0px

    .info-row .info-col
      padding: 0px
