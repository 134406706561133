.welcome-modal
  padding: 16px
  .highlight
    color: var(--highlightFontColor)
  .cta
    margin-top: 32px
    .primary-btn
      width: 100%
      &:nth-of-type(1)
        margin-bottom: 12px
