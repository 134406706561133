#deposit-page
  //display: flex
  //justify-content: center
  //flex-direction: column
  //align-items: center

  .header
    font-size: 32px
    color: var(--highlightFontColor)
    // margin-bottom: 32px

  .sub-header
    font-size: 14px
    text-align: center
    // font-weight: 600
    margin: 0 auto 24px
    max-width: 500px

  .layout
    display: flex
    width: 100%
    padding: 0

    @media (max-width: 740px)
      flex-direction: column

    .tabs
      // margin-bottom: 24px
      min-width: 100px
      // margin-right: 24px

      @media (min-width: 740px)
        border-right: 1px solid rgba(#fff, 0.1)

      @media (max-width: 740px)
        border-bottom: 1px solid rgba(#fff, 0.1)
      //   margin-right: 0

      .tab
        padding: 16px
        cursor: pointer
        border-bottom: 1px solid rgba(#fff, 0.1)

        &:hover
          background: rgba(#000, 0.1)
          color: #fff

        &.active
          color: var(--highlightFontColor)
          font-weight: bold

        &.disabled, &.disabled:hover
          opacity: 0.2
          background: none
          color: var(--whiteColor)

    .content
      flex: 1
      margin-left: auto
      margin-right: auto
      padding: 16px

      @media (max-width: 740px)
        margin: 0

    .title
      font-size: 16px
      font-weight: 600
      text-align: center
      margin-bottom: 24px


.deposit-withdraw-layout
  .header-area
    display: flex
    .title-info
      font-size: 20px
      font-weight: bold
      color: var(--whiteColor)
      letter-spacing: 1.2px
      margin-bottom: 18px
  .main-content
    margin-bottom: 24px
    display: flex
    @media (max-width: 740px)
      display: block
    .secondary-info
      margin-bottom: 12px
    .warning
      display: flex
      align-items: center
      color: rgb(255, 176, 24)
      margin-bottom: 12px
      svg
        font-size: 18px
      p
        margin-left: 8px
    .qr-part
      margin-left: 24px
      @media (max-width: 740px)
        margin: 0 auto
        width: max-content

  .input-area
    margin-bottom: 24px
    .sub-input-area
      width: 100%
      display: flex
      align-items: center

      @media (max-width: 740px)
        display: block

      .input-field
        flex: 1
        @media (max-width: 740px)
        width: 100%
        margin-bottom: 10px
      .copy-btn
        padding: 6px 12px
        background:  rgb(255, 176, 24)
        border-radius: 4px
        color: black
        font-weight: bold
        border-style: none
        margin-left: 12px

        @media (max-width: 740px)
        margin-left: 0px

  .buy-crypto-options
    display: flex
    align-items: center
    justify-content: flex-start
    margin-bottom: 24px
    .buy-crypto-option
      display: flex
      justify-content: center
      align-items: center
      height: 32px
      width: 100px
      background: var(--whiteColor)
      border-radius: 4px
      margin-right: 12px
      img
        width: 72px

  .view-transaction-btn
    cursor: pointer
    color: var(--highlightFontColor)
    text-decoration: underline

  .transaction-history
    margin-top: 32px
    padding: 32px 0
    border-top: 1px solid var(--borderColor)

    table
      width: 100%
      tbody
        &:nth-child(1)
          background: var(--primaryBgColor)
      tr
        display: flex
        justify-content: space-between

        td, th
          flex: 0.3333
          text-align: center
          padding: 12px 0
        td
          border-bottom: 1px solid var(--borderColor)
        td.view-link
            text-decoration: underline


  .addr
    color: #fff
    // font:
  .est
    opacity: 0.5
    margin-top: 10px
    font-style: italic
    font-size: 12px

  .amount-area
    .label-field
      display: flex
      align-items: center
      font-size: 14px
      justify-content: space-between
      width: 100%

    p:nth-of-type(2)
      margin-bottom: 8px

    .input-area
      display: flex
      align-items: center
      background: var(--primaryBgColor)
      width: 100%
      border-radius: 6px
      padding-right: 2px
      margin-bottom: 4px

      .input-field
        width: 100%

    .token-view
      font-weight: 600
      margin-right: 12px
      color: var(--primaryColor)

  .info
    color: var(--highlightFontColor)
    text-align: center
    margin-bottom: 4px

  .fiat-value
    margin-bottom: 16px

  .cta-btn
    display: flex
    align-items: center

    .primary-btn
      font-weight: bold
      width: 100%
    .secondary-btn
      font-weight: bold
      width: 100%


.MuiTab-root
  color: var(--whiteColor) !important

.Mui-selected
  color: var(--primaryColor) !important
