#referral-main
  .box-wrapper
    margin-bottom: 24px

    .title
      font-size: 26px
      font-weight: bold
      color: var(--whiteColor)
      margin-bottom: 16px

    .desc
      font-size: 14px
      margin-bottom: 16px

    .explain b
      color: var(--highlightFontColor)
      font-weight: bold

  .info
    text-align: center
    padding: 32px 0
  .referral-section
    display: flex
    justify-content: flex-start
    margin-bottom: 16px

    .referral-section-selectors
      border: 1px solid var(--borderColor)
      padding: 10px
      margin-right: 10px
      border-radius: 6px
      color: var(--whiteColor)
      cursor: pointer
      &:hover
        background: rgba(0, 0, 0, 0.8)
    .selected
      color: var(--highlightFontColor)
      text-shadow: 1px 1px 10px var(--highlightFontColor)
      border: 1px solid var(--highlightFontColor)

  .referral-code-section
    .input-box
      display: flex

    .referral-input
      flex: 1
      border-radius: 10px
      background-color: rgba(0, 0, 0, 0)
      border: 1px solid var(--borderColor)
      color: var(--whiteColor)
      padding: 14px
      outline: none

    .add-button
      margin-left: 10px
      // width: 10%
      padding: 14px
      border: 1px solid var(--borderColor)
      border-radius: 6px
      background-color: var(--primaryColor)
      color: var(--whiteColor)

      &:disabled
        opacity: 0.5
        background-color: #999
