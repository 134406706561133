html, body, #root, #root > div
  height: 100%
  width: 100%

$side_menu_width: 240px

#side-menu
  position: relative
  width: $side_menu_width
  border-right: rgba(255, 255, 255, 0.1) 1px solid
  background-color: rgba(0, 0, 0, 0.1)

  .side-menu-item
    padding: 16px
    display: flex
    border-bottom: rgba(255, 255, 255, 0.1) 1px solid
    align-items: center

    &:hover
      color: #fff

  .side-menu-text
    margin-left: 16px
    font-size: 14px
    font-weight: 600

  .socials
    position: absolute
    bottom: 32px
    text-align: center
    width: 100%
    .title
      margin-bottom: 12px
    a
      margin: 0 6px





#page-content
  flex: 1
  display: flex
  flex-direction: row
  overflow: auto

  #main
    flex: 1
    display: flex
    #main-content
      display: flex
      flex-direction: column
      justify-content: space-between
      padding: 32px
      flex: 1
      overflow: auto

      @media (max-width: 740px)
        padding: 16px


#page
  flex: 1
  height: 100%
  // position: fixed
  // top: 0
  // bottom: 0
  // left: 0
  // right: 0
  display: flex
  flex-direction: column
  .socials
    width: 100%
    text-align: center
    margin-top: 32px
    .title
      margin-bottom: 12px
    a
      margin: 0 6px


@media (max-width: 600px)
  #side-menu
    max-width: none


  #page-content
    display: block
    // overflow: auto



.mo-menu-main
  align-items: center
  display: flex
  flex-direction: column
  position: fixed
  top: 93px
  width: 100%
  left: 0
  background: #1e1d1d
  height: 100%
  overflow-y: scroll
  z-index: 111

  .mo-menu-balance
    margin-top: 15px

  .mo-menu-connect-wallet
    margin-top: 12px
