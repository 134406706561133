#username-page
  max-width: 800px
  margin: 16px auto

  .title
    font-size: 16px
    font-weight: 600
    text-align: center
    margin-bottom: 24px


.username-layout
  .input-area
      display: flex
      align-items: center
      background: var(--primaryBgColor)
      width: 100%
      border-radius: 6px
      padding-right: 2px
      margin-bottom: 18px

      .input-field
        width: 100%

  .error-msg
    color: red

  .cta-btn
    display: flex
    align-items: center

    .primary-btn
      font-weight: bold
      width: 100%
