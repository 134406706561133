.account-button
  .logged-in
    button
      background: transparent
      color: var(--whiteColor)
      border: 1px solid var(--primaryColor)
      padding: 0 24px
      min-height: 38px
      span
        margin-left: 12px
  .not-logged-in
    display: flex
    align-items: center
    .login
      height: 37px
      min-height: 0
      padding: 0 24px
      // color: var(--whiteColor)
      font-weight: bold
      // background: transparent
      border-style: none
      @media (max-width: 740px)
        height: 32px
        padding: 0 16px

    .register
      padding: 0 24px
      height: 37px
      font-weight: bold

      @media (max-width: 740px)
        height: 32px
        padding: 0 12px
