.token-selector
  background: var(--secondaryBtnColor)
  color: #fff
  padding: 9px 18px
  border-radius: 8px
  box-shadow: 2px 2px 5px rgba(0,0,0,0.5)
  border-style: none
  margin-right: 12px
  cursor: pointer
  .token-info
    display: flex
    justify-content: center
    align-items: center
    span
      margin-left: 6px
      margin-right: 10px

