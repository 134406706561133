.bets-history
  //max-width: 800px
  margin: 0 auto
  width: 100%
  //height: 35px
  //overflow-x: hidden
  //text-align: right
  //direction: rtl
  max-width: 800px
  //margin: 0 auto
  display: flex
  flex-direction: row-reverse
  overflow: scroll
  min-height: 36px


  .individual-bet
    padding: 6px 10px
    border-radius: 16px
    margin-right: 4px
    margin-bottom: 4px
    display: inline-block
    text-decoration: none
    min-width: 74px
    text-align: center
    direction: rtl
    max-height: 32px
    span
      opacity: 0.5
