:root {
  --primaryBgColor: #10121d;
  --secondaryBgColor: #141624;
  --primaryThemeColor: #221050;
  --secondaryThemeColor: #0a0e15;
  --primaryColor: #6457ff;
  --primaryFontColor: #a1a2a7;
  --secondaryFontColor: #7165ec;
  --highlightFontColor: #fdca39;
  --whiteColor: #e4e5e8;
  --secondaryBtnColor: #1f2432;
  --borderColor: rgba(255, 255, 255, 0.1);
}

* {
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
  padding: 0;
}

body {
  background: var(--primaryBgColor);
  /* padding: 0 24px; */
  color: var(--primaryFontColor);
  font-family:
    "Poppins",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
  font-size: 14px;
}

.link {
  color: var(--highlightFontColor);
  text-decoration: underline;
  cursor: pointer;
}

.cursor {
  cursor: pointer;
}

h2,
h3 {
  color: var(--highlightFontColor);
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 16px;
  text-transform: uppercase;
}

h3 {
  font-size: 16px;
  color: #fff;
}
