.balance-page
  .balance-info-data
    margin-bottom: 24px
    .title
      font-size: 26px
      font-weight: bold
      color: var(--whiteColor)
      margin-bottom: 32px
    .info-part
      display: flex
      justify-content: space-between
      align-items: flex-end
      @media (max-width: 740px)
        display: block
      .info-row
        display: flex
        align-items: flex-start
        justify-content: flex-start
        .info-data
          text-align: left
          margin-right: 32px
          .info-title
            font-size: 14px
            margin-bottom: 4px
          .info-value
            font-size: 16px
            color: var(--whiteColor)
            font-weight: bold
      .claim-button
        @media (max-width: 740px)
          margin-top: 32px
        button
          padding: 8px 24px
          border-radius: 4px
          cursor: pointer
          font-weight: bold
          border: 1px solid var(--highlightFontColor)
          background-color: rgba(0, 0, 0, 0.1)
          color: var(--highlightFontColor)
          &:disabled
            border: 2px solid var(--borderColor)
            cursor: not-allowed
            color: var(--primaryFontColor)

  .all-balances-data
    margin-bottom: 12px
    .balance-info-row
      display: flex
      justify-content: space-between
      align-items: center
      @media (max-width: 740px)
        display: unset
      .left-part
        display: flex
        align-items: center
        justify-content: flex-start
        .token-icon
          margin-right: 12px
        .info-part
          .label
            font-size: 16px
            margin-bottom: 0
            font-weight: unset
          .data
            color: var(--whiteColor)
            font-size: 16px
          .desc
            opacity: 0.5
            //font-weight: bold
      .action-area
        flex: 1
        display: flex
        justify-content: end
        color: var(--highlightFontColor)
        cursor: pointer
        @media (max-width: 740px)
          justify-content: center
          margin-top: 12px
        p
          width: max-content
        .disabled
          color: var(--primaryFontColor)
          cursor: not-allowed


