#allbets
  display: flex
  justify-content: center
  flex-direction: column
  align-items: center



  .allbets-sub-header
    font-size: 14px
    text-align: center
    // font-weight: 600
    margin: 0 auto 24px
    max-width: 500px

  .allbets-social
    display: flex
    align-items: center
    width: 20%
    justify-content: space-between

  .table-main
    width: 100%

  #sorter-main
    display: flex
    margin-bottom: 32px
    // margin-right: 40px
    justify-content: center

    .sorter-values
      border: 1px solid var(--primaryColor)
      color: var(--primaryColor)
      border-radius: 15px
      margin: 0 5px
      padding: 5px 15px
      cursor: pointer
      font-weight: 600

    .sorting-selected-value
      background-color: var(--primaryColor)
      color: white !important
