.login-register-modal
  padding: 0 24px 24px

  .header
    font-size: 18px
    color: var(--highlightFontColor)
    font-weight: bold

  .intro
    margin: 12px 0

  .form
    .asterisk
      color: firebrick !important
    .label-field
      margin-bottom: 24px
    .label-field:nth-of-type(0)
      margin-bottom: 0

    .input-field
      width: 100%

    .primary-cta
      margin-top: 32px
      .primary-btn
        width: 100%


    .divider
      text-align: center
      margin: 12px 0
      position: relative
      &:before
        content: ""
        position: absolute
        width: 100%
        border: 1px solid var(--borderColor)
        left: 0
        top: 50%
        z-index: 0

      span
        background: var(--secondaryBgColor)
        z-index: 1
        position: relative
        padding: 8px 12px


    .other-option
      //display: flex
      //justify-content: space-evenly

      .web3-wallet
        border-style: none
        border-radius: 4px
        height: 44px

        &:hover
          background: #FFFFFF20
          color: lightgray

        img
          width: 34px
          height: 34px
          margin-right: 10px

        &.google
          background: white
          font-weight: bold
          color: rgba(#000, 0.5)
          display: flex
          align-items: center
          justify-content: center
          width: 100%
          margin-bottom: 12px

        &.discord
          background: #5865F2
          font-weight: bold
          color: white
          display: flex
          align-items: center
          justify-content: center
          width: 100%
          margin-bottom: 12px

        &.twitter
          background: white
          font-weight: bold
          color: #1DA1F2
          display: flex
          align-items: center
          justify-content: center
          width: 100%
          margin-bottom: 12px


    .secondary-cta
      .secondary-btn, .primary-btn
        width: 100%
        height: 44px
