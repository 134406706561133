.token-selector-modal
  width: 280px
  @media (max-width: 720px)
    width: 100vw
  .option-items
    padding-bottom: 12px
    .option-item
      display: flex
      align-items: center
      justify-content: space-between
      padding: 8px 24px
      cursor: pointer
      &.active
        background: #FFFFFF10
        color: var(--whiteColor)
      &:hover
        background: #FFFFFF05
      .token-info
        span
          margin-left: 6px
      .token-balance-info
        text-align: end

