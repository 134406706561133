#app-footer
  background: rgba(0, 0, 0, 0.1)
  border-top: 1px solid rgba(255, 255, 255, 0.1)
  display: flex
  justify-content: space-between
  padding: 16px
  align-items: center
  @media (max-width: 740px)
    padding: 4px 16px

  .branding-w-logo
    display: flex
    align-items: center

    img
      width: 60px
      border-radius: 50%
      margin-right: 12px
      @media (max-width: 600px)
        width: 42px
        margin-right: 6px

    p
      line-height: 1
      font-size: 24px
      text-transform: uppercase
      color: var(--highlightFontColor)
      font-weight: bold
      @media (max-width: 600px)
        font-size: 14px

    .sub
      font-size: 14px
      @media (max-width: 600px)
        font-size: 10px

  .menu-ul
    display: flex
    flex: 1
    justify-content: space-around

  .menu-li
    margin: 0 12px
    padding: 4px 0
    font-size: 14px
    cursor: pointer
    border-bottom: 1px solid transparent
    text-decoration: none
    color: inherit
    transition: 0.2s ease
    text-align: center
    span
      font-size: 12px

    &:hover, &.active
      color: var(--highlightFontColor)
      //border-bottom: 1px solid var(--highlightFontColor)

  .balance-button
    padding: 0 16px
    @media (max-width: 600px)
      padding: 0

  .right-cta
    display: flex
