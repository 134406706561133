.box-wrapper {
  border-radius: 16px;
  /* max-width: 800px; */
  margin-left: auto;
  margin-right: auto;
  background: var(--secondaryBgColor);
  border: 1px solid var(--borderColor);
  padding: 24px;
}

.box-wrapper-no-border {
  border-radius: 4px;
  /* max-width: 800px; */
  margin-left: auto;
  margin-right: auto;
  background: var(--primaryBgColor);
  padding: 16px;
}

@media (max-width: 740px) {
  .box-wrapper {
    padding: 16px;
  }
  .box-wrapper-no-border {
    padding: 8px;
  }
}

.label {
  font-size: 12px;
  margin-bottom: 8px;
  font-weight: bold;
}

/* ============ Input Fields ============ */

.input-field {
  padding: 4px 16px;
  font-size: 14px;
  color: var(--whiteColor);
  height: 44px;
  border-radius: 4px;
  background: var(--primaryBgColor);
  border: none;
  display: flex;
  align-items: center;
}

.input-field:focus {
  outline: none;
}

/*
.input-slider {
  width: 100%;
  height: 12px;
  -webkit-appearance: none;
  background: var(--primaryBgColor);
  outline: none;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 1);
}
.input-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 15px;
  height: 25px;
  border-radius: 50%;
  background: var(--highlightFontColor);
  cursor: pointer;
  border: 4px solid #333;
  box-shadow: -407px 0 0 400px var(--primaryColor);
}*/

.input-slider {
  -webkit-appearance: none;
  appearance: none;
  background: #00e700;
  cursor: pointer;
  width: 100%;
  overflow: hidden;
  border-radius: 4px;
}

/***** Track Styles *****/
/***** Chrome, Safari, Opera, and Edge Chromium *****/
.input-slider::-webkit-slider-runnable-track {
  background: #00e700;
  height: 18px;
  border-radius: 4px;
}

/******** Firefox ********/
.input-slider::-moz-range-track {
  background: #00e700;
  height: 18px;
  border-radius: 4px;
}

/***** Thumb Styles *****/
/***** Chrome, Safari, Opera, and Edge Chromium *****/
.input-slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  background-color: white;
  height: 20px;
  width: 20px;
  /*border-radius: 10px;*/
  box-shadow:
    -407px 0 0 400px #e80f3d,
    2px 2px 5px black;
}

/***** Firefox *****/
.input-slider::-moz-range-thumb {
  -webkit-appearance: none; /* Override default look */
  background-color: var(--whiteColor);
  height: 12px;
  width: 12px;
  border-radius: 50%;
  box-shadow: -407px 0 0 400px #e80f3d;
}

/* ============ Button ============ */

.primary-btn {
  height: 100%;
  min-height: 44px;
  width: max-content;
  padding: 4px 8px;
  font-size: 14px;
  background: var(--primaryColor);
  border-radius: 4px;
  cursor: pointer;
  transition: 0.5s ease;
  color: var(--whiteColor);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-style: none;
}

.primary-btn:disabled {
  cursor: not-allowed;
  background: rgb(128, 128, 128);
}

.primary-btn:disabled:hover {
  transform: none;
}

.primary-btn:hover {
  transform: scale(1.02);
}

.secondary-btn {
  height: 100%;
  min-height: 32px;
  width: max-content;
  padding: 4px 8px;
  font-size: 16px;
  color: darkgray;
  background: #ffffff10;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.5s ease;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-style: none;
}

.secondary-btn:hover {
  transform: scale(1.02);
}

/* ============ Table ============ */
.table-main {
  /* width: 80%; */
  margin: 0 auto;
  /* max-width: 800px; */
  border-radius: 16px;
  border: 1px solid var(--borderColor);
  overflow: hidden;
}

.table-main .table-head {
  display: flex;
  background-color: var(--primaryColor);
  color: var(--whiteColor);
  padding: 16px;
  font-weight: bold;
}

.table-main .table-head-li {
  text-align: center;
}

.table-main .table-row {
  display: flex;
  padding: 16px;
  cursor: pointer;
  align-items: center;
  color: var(--whiteColor);
}

.table-main .table-row:nth-of-type(2n) {
  background: var(--secondaryBgColor);
}

.table-main .table-row:hover {
  background: var(--secondaryBgColor);
}

.table-main .table-row .table-row-li {
  text-align: center;
  flex: 0.4;
}

.table-main .table-row .table-row-li span {
  font-size: 12px;
  display: block;
  opacity: 0.5;
}

/* ============ Modal ============ */

/* ============ Snackbar ============ */
.snackbar-root {
  top: 80px !important;
}

.snackbar .links {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 4px;

  &:hover {
    text-decoration: none;
  }
}

.snackbar .header {
  display: flex;
  align-items: center;
  width: 100%;
  /*height: 50px;*/
  height: 8px;
  padding: 0 12px 0 18px;
  justify-content: space-between;
  border-radius: 4px 4px 0 0;
}

.snackbar .body {
  /*background: white;*/
  backdrop-filter: blur(100px);
  border-radius: 0 0 4px 4px;
  padding: 12px;
  font-weight: 600;
  font-size: 14px;
  width: 100%;
}

.snackbar .content {
  background: rgba(255, 255, 255, 0.02);
  backdrop-filter: blur(70px);
  border-radius: 4px 4px 0 0;
  border: 1px solid;
  width: 378px;
  border-image-source: linear-gradient(
    180deg,
    rgba(255, 116, 38, 0.1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  margin-right: 15px;
  color: #ffffff;
  opacity: 0.88;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  @media (max-width: 600px) {
    width: 100%;
  }
}

/* ============ Opitons ============ */
.ul-items {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.li-item {
  padding: 8px 24px;
  background: #ffffff10;
  border-radius: 6px;
  margin-right: 12px;
  border: 1px solid var(--borderColor);
  width: max-content;
  margin-bottom: 4px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}

.li-item.selected {
  box-shadow: 1px 1px 5px #ffffff30;
  color: var(--highlightFontColor);
  text-shadow: 1px 1px 10px var(--highlightFontColor);
  border: 1px solid var(--highlightFontColor);
}
