.custom-modal
  width: 500px
  background: var(--secondaryBgColor)
  border-radius: 6px
  box-shadow: 0 6px 21px rgba(0, 0, 0, 0.5)

  @media (max-width: 600px)
    width: 100vw
    //bottom: 0
    //position: absolute

  b
    color: var(--highlightFontColor)

  a
    color: var(--highlightFontColor)

    &:visited
      color: var(--highlightFontColor)
      text-decoration: none
      border-bottom: 2px dotted var(--highlightFontColor)

    color: var(--highlightFontColor)
    text-decoration: none
    border-bottom: 2px dotted var(--highlightFontColor)

  .link
    color: var(--highlightFontColor)
    text-decoration: none
    border-bottom: 2px dotted var(--highlightFontColor)

  .custom-modal-title
    padding: 16px 24px
    display: flex
    align-items: center
    justify-content: space-between

  .custom-modal-title-content
    flex: 1
    color: var(--highlightFontColor)
    text-align: center
    font-size: 18px
    font-weight: bold

  .custom-modal-content
    // padding: 0 16px 16px 16px

  .close-icon
    font-size: 16px
    font-weight: bold

    /* color: var(--highlightFontColor);
    cursor: pointer

.ReactModal__Content
  @media (max-width: 420px)
    height: max-content !important
    inset: unset !important
    margin-right: 0 !important
    transform: unset !important
    bottom: 0 !important

