$chat_view_width: 340px;
$side_menu_width: 240px;
@media (max-width: 1024px) {
  $chat_view_width: 0px;
  $side_menu_width: 0px;
}

$num_icons: 9;
//$icon_width: calc(((100vw - 64px - ($chat_view_width + $side_menu_width))/3) - 24px);
//$icon_height: $icon_width;

$icon_width: 120px;
$icon_height: $icon_width;

.debug {
  position: fixed;
  top: 4px;
  left: 50%;
  transform: translateX(-50%);
  padding: 4px;
  background: white;
  border: 1px solid rgba(black, 0.3);
  font-family: monospace;
  font-size: 1.6rem;
}

html,
body {
  height: 100%;
}

.slots {
  position: relative;
  //width: 3.5 * $icon_width;
  //height: 3 * $icon_height;
  display: flex;
  justify-content: center;
  //padding: 32px;
  //background: linear-gradient(45deg, grey 0%, lightgray 100%);
  //border-top: 1px solid rgba(white, 0.6);
  //border-right: 1px solid rgba(white, 0.6);
  //border-left: 1px solid rgba(black, 0.4);
  //border-bottom: 1px solid rgba(black, 0.4);
  //box-shadow: -2px 2px 3px rgba(black, 0.3);
  //background: transparent;
  width: max-content;
  margin: 0 auto;
  border: none;
  border-radius: 16px;
  //background: url("../../static/images/slot/background.png") center;

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    width: 10px;
    height: 2px;
    background-color: rgba(black, 0.5);
  }

  &::before {
    left: 0;
    transform: translate(-200%, -50%);
  }

  &::after {
    right: 0;
    transform: translate(200%, -50%);
  }

  @keyframes win1 {
    0% {
      //background: linear-gradient(45deg, orange 0%, yellow 100%);
      box-shadow: 0 0 120px orange;
    }
    100% {
      //background: linear-gradient(45deg, grey 0%, lightgrey 100%);
      box-shadow: -2px 2px 3px rgba(black, 0.3);
    }
  }

  @keyframes win2 {
    0% {
      //background: linear-gradient(45deg, lightblue 0%, lightgreen 100%);
      box-shadow: 0 0 120px lightgreen;
    }
    100% {
      //background: linear-gradient(45deg, grey 0%, lightgrey 100%);
      box-shadow: -2px 2px 3px rgba(black, 0.3);
    }
  }

  &.win1 {
    animation: win1 200ms steps(2, end) infinite;
  }

  &.win2 {
    animation: win2 200ms steps(2, end) infinite;
  }

  .reel {
    position: relative;
    width: $icon_width;
    height: calc(3 * $icon_height);
    border: 1px solid rgba(black, 0.3);
    border-radius: 3px;
    overflow: hidden;
    background-image: url(https://assets.codepen.io/439000/slotreel.webp);
    background-position: 0 0;
    background-repeat: repeat-y;
    background-size: $icon_width calc($icon_width * $num_icons);
    margin: 0 6px;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(
        rgba(black, 0.4) 0%,
        transparent 30%,
        transparent 70%,
        rgba(black, 0.4) 100%
      );
      box-shadow: inset 0 0 6px 2px rgba(black, 0.3);
    }
  }

  p {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 100%;
    transform: translate(-50%, calc(100% + 30px));
    text-align: center;
    font-size: 0.8rem;
    color: #444;

    a {
      color: red;
      font-weight: 700;
    }
  }
}
