#slot
  background: url("../../static/images/slot/background.png") center
  background-size: cover
  width: calc(100% + 64px)
  min-height: calc(100vh - 64px)
  margin: -32px 0 0 -32px
  @media (max-width: 1040px)
    min-height: calc(100vh - 136px)
  @media (max-width: 740px)
    width: calc(100% + 32px)
    margin: -16px 0 0 -16px

  .playing-area
    width: 100%
    height: 100%
    padding: 0
    display: flex
    justify-content: center
    align-items: center

  .actions
    margin-top: 32px
    .playing-area-row
      display: flex
      align-items: center
      align-content: center
      justify-content: center
      .left-area
        //width: 33.33%
        display: flex
        align-items: center
        justify-content: flex-end
      .cta-area
        //width: 33.33%
        display: flex
        align-items: center
        justify-content: center
        position: relative
        .info
          color: var(--highlightFontColor)
          text-align: center
          //max-width: 50%
          //margin: 15px auto
          position: absolute
          width: 590px
          left: -245px
          bottom: -80px
          @media (max-width: 1440px)
            bottom: -140px
        .primary-btn
          margin-left: auto
          margin-right: auto
          width: 100px
          height: 100px
          font-weight: bold
          border-radius: 50%
          background: url("../../static/images/slot/spin-button.png") center
          background-size: 100px 100px
          font-size: 18px
          color: #dcb77c
          text-shadow: 0 0 6px black

          &.funding-needed
            //border: 1px solid var(--highlightFontColor)
            //background-color: rgba(0, 0, 0, 0.1)
            //color: var(--highlightFontColor)
      .right-area
        //width: 33.33%
        display: flex
        align-items: center
        justify-content: center


  .amount-field
    display: flex
    align-items: center
    justify-content: flex-start
    .input-field
      max-width: 100%
      background: transparent

  /* Wooden plank to display numbers */
  .wooden-plank-main
    width: max-content
    .label
      color: #ad4316
      font-size: 14px
      text-align: center
      margin-bottom: -4px
      text-shadow: 0 0 3px black
      -webkit-user-select: none
      -ms-user-select: none
      user-select: none
    .wooden-plank
      position: relative
      height: 80px
      width: 240px
      display: flex
      align-items: center
      justify-content: center
      img
        position: absolute
        width: 100%
        height: 100%
        z-index: 1
      .children
        z-index: 2
        margin-bottom: 20px
        max-width: 40%
        //overflow: hidden
        text-align: center
        .plank-text
          font-size: 18px
          color: #dcb77c
          text-shadow: 0 0 6px black
          font-weight: bold
          -webkit-user-select: none
          -ms-user-select: none
          user-select: none







