#home
  .promotional-banners
    max-width: 800px
    //height: 600px
    width: 100%
    //border-radius: 16px
    //margin-bottom: 24px
    display: block
    overflow: hidden
    margin: 0 auto 24px
    border-radius: 16px
    box-shadow: 2px 2px 5px 5px #00000060
    @media (max-width: 740px)
      //height: 280px

  .carousel-custom-item
    img
      width: 100%
      height: 400px
      @media (max-width: 740px)
        height: 250px

  .games-section
    max-width: 800px
    margin: 30px auto

    .title
      font-size: 24px
      font-weight: bold
      color: var(--highlightFontColor)
      margin-bottom: 12px

    .games-row
      display: flex
      overflow-x: auto
      overflow-y: hidden
      white-space: nowrap

      .games-col
        min-width: 25%
        border-radius: 16px

        .game-card
          position: relative
          height: 250px
          cursor: pointer
          // width: 90%
          background-color: #000
          background-position: center
          background-size: cover
          border-radius: 16px

          img
            width: 100%
            height: 100%
            object-fit: cover
            overflow: hidden


@media (max-width: 740px)
  #home .games-section .games-row .games-col
    min-width: 60%

  #home .games-section .games-row .games-col .game-card
    height: 220px

